import React from "react";

import SvgIcon, {SvgIconProps} from "./SvgIcon";

const BarBell = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 632 632">
      <circle cx="316" cy="316" r="316" fill="#FFC700" />
      <rect x="176" y="302" width="280" height="28" fill="black" />
      <rect x="148" y="216" width="28" height="200" fill="black" />
      <rect x="112" y="236" width="28" height="160" fill="black" />
      <rect x="76" y="266" width="28" height="100" fill="black" />
      <rect
        width="28"
        height="200"
        transform="matrix(-1 0 0 1 484 216)"
        fill="black"
      />
      <rect
        width="28"
        height="160"
        transform="matrix(-1 0 0 1 520 236)"
        fill="black"
      />
      <rect
        width="28"
        height="100"
        transform="matrix(-1 0 0 1 556 266)"
        fill="black"
      />
    </SvgIcon>
  );
};

export default BarBell;
