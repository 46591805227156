import * as React from "react";
import styled from "styled-components";
import {StaticImage} from "gatsby-plugin-image";

import Layout from "../components/Layout";
import AppleAppStore from "../components/svg/AppleAppStore";
import BarBell from "../components/svg/BarBell";
import OneRepMax from "../components/svg/OneRepMax";
import hexToRGBA from "../utils/hexToRGBA";

const Hero = styled.div`
  background-color: ${props => props.theme.color.common.white};
  display: flex;
  justify-content: space-between;

  width: 100%;
  max-width: 1024px;

  @media (max-width: 760px) {
    align-items: center;
    flex-direction: column;
  }
`;

const HeroText = styled.h1`
  ${props => props.theme.typography.h3};
  font-weight: 500;
  margin: 0;

  max-width: 430px;

  @media (max-width: 400px) {
    ${props => props.theme.typography.h4};
    font-weight: 500;
  }
`;

const HeroTextContent = styled.div`
  margin-right: 2rem;
  padding-top: 164px;

  @media (max-width: 838px) {
    padding-top: 100px;
  }

  @media (max-width: 760px) {
    margin-right: 0;
    margin-bottom: 4rem;
    padding-top: 64px;
  }
`;

const HeroImage = styled.div`
  max-height: 560px;
  max-width: 402px;
`;

const SubText = styled.h2`
  ${props => props.theme.typography.h6};
  color: ${props => hexToRGBA(props.theme.color.secondary.light, 0.6)};
  font-weight: 300;
  max-width: 400px;
`;

const AppleDownloadLink = styled.a`
  display: block;
  outline: none;

  margin-top: 2rem;
  height: 40px;
`;

const Features = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding: 32px 0;
  width: 100%;
`;

const FeaturesTitle = styled.div`
  ${props => props.theme.typography.h5};
  text-align: center;

  margin: 0 auto;
  margin-bottom: 4rem;
  max-width: 350px;
`;

const FeatureTiles = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-around;

  @media (max-width: 500px) {
    align-items: center;
    flex-direction: column;

    > div:not(:last-child) {
      margin-bottom: 4rem;
    }
  }
`;

const FeatureTile = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
`;

const FeatureName = styled.div`
  ${props => props.theme.typography.subtitle1};
  font-weight: 500;
  margin-bottom: 0.75rem;
`;

const FeatureDesc = styled.div`
  ${props => props.theme.typography.body2};
  color: ${props => hexToRGBA(props.theme.color.secondary.light, 0.6)};
  max-width: 200px;
`;

const FeatureIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

const IndexPage = () => {
  return (
    <Layout>
      <Hero>
        <HeroTextContent>
          <HeroText>Stop thinking about numbers in the gym</HeroText>
          <SubText>
            Estimate your 1 Rep Max, load up your bar and crush your gym goals.
          </SubText>
          <AppleDownloadLink
            target="_blank"
            href="https://apps.apple.com/us/app/coachq/id1559560270">
            <AppleAppStore />
          </AppleDownloadLink>
        </HeroTextContent>
        <HeroImage>
          <StaticImage
            src="../images/iphone_screenshots.png"
            alt="coachq screenshots"
          />
        </HeroImage>
      </Hero>
      <Features>
        <FeaturesTitle>
          Stop wasting time in the gym thinking about numbers.
        </FeaturesTitle>
        <FeatureTiles>
          <FeatureTile>
            <FeatureIcon>
              <OneRepMax height="100px" width="100px" />
            </FeatureIcon>
            <FeatureName>1 Rep Max Calculator</FeatureName>
            <FeatureDesc>
              Don't know your 1 rep max? Quickly calculate it and see a
              percentage scale.
            </FeatureDesc>
          </FeatureTile>
          <FeatureTile>
            <FeatureIcon>
              <BarBell height="100px" width="100px" />
            </FeatureIcon>
            <FeatureName>Get the barbell ready</FeatureName>
            <FeatureDesc>
              Stop wasting time thinking about what plates to put on. Set your
              weight and see the plates you need.
            </FeatureDesc>
          </FeatureTile>
        </FeatureTiles>
      </Features>
    </Layout>
  );
};

export default IndexPage;
