import React from "react";

import SvgIcon, {SvgIconProps} from "./SvgIcon";

const OneRepMax = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 632 632">
      <circle cx="316" cy="316" r="316" fill="#FFC700" />
      <rect x="176" y="129" width="280" height="374.5" rx="16" fill="black" />
      <rect x="197" y="157" width="238" height="77" rx="8" fill="white" />
      <rect x="197" y="258.5" width="56" height="56" rx="8" fill="white" />
      <rect x="197" y="339" width="56" height="56" rx="8" fill="white" />
      <rect x="197" y="419.5" width="56" height="56" rx="8" fill="white" />
      <rect x="288" y="258.5" width="56" height="56" rx="8" fill="white" />
      <rect x="288" y="339" width="56" height="56" rx="8" fill="white" />
      <rect x="379" y="260.25" width="56" height="56" rx="8" fill="white" />
      <rect x="379" y="339" width="56" height="56" rx="8" fill="white" />
      <rect x="288" y="419.5" width="147" height="56" rx="8" fill="white" />
    </SvgIcon>
  );
};

export default OneRepMax;
