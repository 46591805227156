import * as React from "react";

export interface SvgIconProps extends React.SVGProps<SVGSVGElement> {
  /** html class identifier */
  readonly className?: string;
  /** html children to render within component */
  readonly children?: React.ReactNode;
}

const SvgIcon = (props: SvgIconProps) => {
  const {children, ...other} = props;
  return <svg {...other}>{children}</svg>;
};

export default SvgIcon;
